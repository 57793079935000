import React from "react"
import "./styles/Advertise.scss"
import hero from "../resources/images/hero-default.jpg"
import MainContent from "../components/Layout/MainContent"
import Title from "../components/Text/Title"
import BodyContent from "../components/Text/BodyContent"
import Container from "../components/Layout/Container"

function contact() {
  return (
    <Container page="Algemeen wedstrijdreglement" shareImage={hero}>
      <div className="advertise">
        <MainContent imageInternal={hero}>
          <Title>Algemeen wedstrijdreglement</Title>
          <BodyContent>...</BodyContent>
        </MainContent>
      </div>
    </Container>
  )
}

export default contact
